import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import format from 'date-fns/format';
import { Challenges } from '../editor/types/Experiments';
import { getMediaPreview } from '../selectors/media';

const getDatesConfig = (
  challenge: Challenge,
): { lastMod: string; created: string } => {
  if (!challenge) {
    return null;
  }

  const { transitions } = challenge;
  const occurredAt = transitions?.[0]?.occurredAt
    ? new Date(transitions?.[0]?.occurredAt)
    : new Date();
  const lastDate = format(occurredAt, 'd-MM-yyyy');

  if (transitions?.length === 1) {
    return {
      lastMod: lastDate,
      created: lastDate,
    };
  }

  return {
    lastMod: lastDate,
    created: format(
      transitions
        ? new Date(transitions[transitions.length - 1].occurredAt)
        : new Date(),
      'd-M-yyyy',
    ),
  };
};

const getPricingData = (
  challenge: Challenge,
): { currency: string; price: string } => {
  if (!challenge) {
    return null;
  }

  const currency = challenge.settings.pricing?.singlePayment?.price?.currency;

  return {
    ...(currency && { currency }),
    price: challenge.settings.pricing?.singlePayment?.price?.amount || '0',
  };
};

const getImageData = (
  challenge: Challenge,
  shouldSendImageUrl: boolean,
): {
  mainImage: {
    id: string;
    height: string;
    width: string;
    url: string;
    imageAlt: string;
  };
} | null => {
  if (!challenge) {
    return null;
  }

  const { media } = challenge.settings.description;
  if (media?.image?.id) {
    const width = media?.image?.width ? media?.image?.width?.toString() : '800';
    const height = media?.image?.height
      ? media?.image?.height?.toString()
      : '600';
    return {
      mainImage: {
        id: media?.image?.id,
        width,
        height,
        url: shouldSendImageUrl ? getMediaPreview(media, 800, 600) : null,
        imageAlt: challenge.settings.seo.imageAlt || '',
      },
    };
  }

  return null;
};

const getAvailability = (challenge: Challenge): string => {
  // for the secret challenge SEO team add title to page 'Access Forbidden'
  // no matter for Participants of a challenge o Visitor.
  // Bloody workaround.
  if (!challenge?.settings) {
    return 'PRIVATE';
  }

  return challenge.settings.accessRestrictions.accessType;
};

export function renderSeoMetatagsPage(
  flowAPI: ControllerFlowAPI,
  challenge: Challenge,
  itemType: string = 'CHALLENGES_PAGE',
) {
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return;
  }

  const { wixCodeApi } = flowAPI.controllerConfig;
  const seoAPI = wixCodeApi.seo;

  if (!challenge?.settings) {
    seoAPI.renderSEOTags({
      itemType,
      itemData: null,
      seoData: null,
    });

    return;
  }

  const { url, query } = wixCodeApi.location;
  const shareTitle = query.shareTitle;
  const isProgramInItemDataEnabled = flowAPI.experiments.enabled(
    Challenges.enableProgramFieldSEOMetatags,
  );
  const isImageUrlInItemDataEnabled = flowAPI.experiments.enabled(
    Challenges.enableImageUrlFieldSEOMetatags,
  );

  // eslint-disable-next-line no-nested-ternary
  const name = challenge
    ? shareTitle
      ? decodeURIComponent(shareTitle)
      : challenge.settings?.description?.title
    : null;
  const description = challenge
    ? challenge.settings?.description?.details
    : null;

  const itemData = {
    challenge: {
      id: challenge?.id || null,
      ...(getDatesConfig(challenge) || {}),
      url,
      name: name || null,
      ...(description && {
        description,
      }),
      registration: {
        availability: getAvailability(challenge),
        ...(getPricingData(challenge) || {}),
      },
      ...(getImageData(challenge, isImageUrlInItemDataEnabled) || {}),
    },
    program: isProgramInItemDataEnabled ? challenge : undefined,
  };

  seoAPI.renderSEOTags({
    itemType,
    itemData,
    seoData: challenge?.settings?.seo?.seoData,
  });
}
